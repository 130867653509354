.bloomnation-modal {
    background-color: #EFEFEF;
    position: fixed;
    z-index: 999;
    border: 2px solid #F4F4F4;
    border-radius: 3px;
    top: 20%;
    left: 30%;
    min-height: 250px;
    min-width: 500px;
    display: none;
}
.bloomnation-modal-body {
    float: left;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
}
.bloomnation-modal-footer {
    float: left;
    width: 100%;
    min-height: 30px;
}
.bloomnation-modal-header {
    float: left;
    width: 100%;
    min-height: 30px;
}

/* Cart Message Popup */
.cart-message-box
{
  float: left;
  width: 100%;
}
.cart-message-img
{
  width: 200px;
  float: left;
  min-height: 250px;
  background-color: #ffffff;
}
.cart-message-img img {
  width: 180px;
  margin-left: 10px;
  margin-top: 10px;
}
.cart-message-input
{
  float: right;
  min-height: 250px;
  width: 530px;
}
.cart-message-input textarea {
  width: 100%;
  height: 250px;
  border: none;
  color: #242424;
  font-family: cursive;
  font-size: 20px;
}
.cart-message-action-block {
  width: 500px;
  margin-left: 250px;
  float: left;
}
.cart-message-action-block-left {
  width: 230px;
  float: left;
  margin-top: 5px;
}
.cart-message-action-block-right {
  width: 250px;
  float: right;
  margin-top: 25px;
}
.cart-message-action-block-right div {
  margin-top: 5px;
}
.cart-message-continue {
  width: 100%;
}
span.cart-message-action-block-occasion {
  color: #B3B3B3;
  font-size: 13px;
}
span.cart-message-goback {
  color: #545454;
  margin-right: 10px;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
}
.cart-message-or {
  color: #5A5A5A;
}
.cart-message-skip {
  color: #A8456F;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
}
/* checkout-cart-index page begin */
.checkout-cart-index .cart {
  margin: 35px auto 0;
  max-width: 1040px;
  padding: 0 15px 30px;
}

.checkout-cart-index .cart .crossells {
  min-height: 300px;
  padding-left: 0;
  padding-right: 0;
}
.checkout-cart-index .cart span.header-anonce {
  display: block;
  text-align: center;
  font-size: 17px;
  color: #00B14B;
  padding-left: 10px;
  font-weight: 600;
}
.header-anonce img {
    display: inline-block;
    width: 16px;
    height: auto;
    vertical-align: middle;
    margin-right: 3px;
    margin-top: -4px;
}
.checkout-cart-index .cart .checkout-cart-main {
  padding-left: 0;
  padding-right: 0;
  /*max-width: 500px*/;
    margin: 40px auto;
}

.checkout-cart-index .cart .img-box {
  float: left;
  padding-left: 0;
  padding-right: 0;
margin-bottom: 30px;
}
.checkout-cart-index .cart .img-box {
    display: none;
}
.checkout-cart-index .cart .img-box img {
    width: 100%;
}
.checkout-cart-index .cart .img-box .small-images img {
    display: inline-block;
    width: 80px;
    margin-top: 10px;
    margin-right: 3px;
}
.checkout-cart-index .cart .crossells-list {
  float: left;
  width: 100%;
  margin-top: 20px;
/* remove whitespace from inline block*/
  font-size: 0;
}
.checkout-cart-index .cart .crossel-image-info {
  padding: 5px;
}
.checkout-cart-index .cart .crossel-image-name,
.checkout-cart-index .cart .crossel-image-price {
    color: #555;
    font-size: 11px;
    font-weight: 600;
}
.checkout-cart-index .cart .crossel-image-name {
  float: left;
  max-width: 70%;
}
.checkout-cart-index .cart .crossel-image-price {
  float: right;
  max-width: 30%;
}
.checkout-cart-index .crossel-wrapper {
    float: none;
    display: inline-block;
    padding: 2.5px;
    margin-bottom: 5px;
    overflow: hidden;
    vertical-align: top;
}
.checkout-cart-index .cart .crossel-image-box {
  /*width: 160px;*/
  /*padding: 15px;*/
  border: 1px solid #ccc;
  border-radius: 4px;
}
.checkout-cart-index .cart .crossel-image-box.active {
    border-color: #00B04B;
}
.checkout-cart-index .cart .crossel-image-box img {
    width: 100%;
    height: auto;
    padding: 5px 5px 0;
    border-radius: 4px;
}
.checkout-cart-index .cart a.add-to-cart,
.checkout-cart-index .cart .add-to-cart-btn {
  display: inline-block;
  width: 100%;
  color: #00B04B;
  text-align: center;
    font-size: 10px;
    font-weight: 600;
    padding: 5px 0;
  border-top: 1px solid #ccc;
}
.checkout-cart-index .cart a.add-to-cart:hover,
.checkout-cart-index .cart .add-to-cart-btn:hover {
    text-decoration: none;
    background: #00B04B;
    color: #fff;
    border-color: #00B04B;
}
.checkout-cart-index .cart .add-to-cart-btn {
    font-size: 12px;
}
.checkout-cart-index .cart span.crossells-title,
.checkout-cart-index .cart span.crossells-title-line2 {
    color: #353535;
}
.checkout-cart-index .cart span.crossells-title {
  display: inline-block;
  width: 100%;
  text-align: center;
  font-size: 19px;
}
.checkout-cart-index .cart span.crossells-title-line2 {
  display: inline-block;
  width: 100%;
  text-align: center;
  font-size: 15px;
}

.checkout-cart-index .cart .btn-proceed-checkout {
    display: block;
    width: 100%;
    margin: 15px auto;
    background-color: #00b551;
    font-weight: 800;
    width: 100%;
    max-width: 100%;
    border-radius: 2px;
    border: 0;
    padding: 10px;
}
.checkout-cart-index .cart .btn-proceed-checkout:hover {
    border: 0;
    background: #03A24A;
}
.checkout-cart-index .cart .checkout-cart-header {
  background-color: #efefef;
  padding: 20px;
}

.checkout-cart-index .cart .page-title {
  border: medium none;
  margin: 0 15px;
  padding: 0 0 58px;
}

.checkout-cart-index .cart .page-title h1 {
  float: left;
}

.checkout-cart-index .cart .page-title .checkout-types {
  float: right;
}

.checkout-cart-index .data-table {
  width: 98%;
  border-collapse: separate;
  border-radius: 2px;
  border-top: 6px solid #575757;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  margin: 8px;
}
.checkout-cart-index .data-table th, .data-table td, .data-table td a {
  color: rgba(0, 0, 0, 0.67);
}
.checkout-cart-index .data-table td a.product-image, .data-table td a.product-image img {
  display: block;
  margin: 0 auto;
}
.checkout-cart-index .data-table td a:hover {
  color: #000000;
  text-decoration: none;
}
.checkout-cart-index .data-table tbody td {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-color: -moz-use-text-color #E9E9E9 #E9E9E9 -moz-use-text-color;
  border-image: none;
  border-right: 1px solid #E9E9E9;
  border-style: none solid solid none;
  border-width: medium 1px 1px medium;
}
.checkout-cart-index .data-table tbody tr:last-child td {
  border-bottom: medium none;
}
.checkout-cart-index .data-table thead, .data-table tfoot {
  background: none repeat scroll 0 0 #FAFAFA;
}
.checkout-cart-index .data-table thead th {
  box-shadow: 0 -4px 6px -4px rgba(0, 0, 0, 0.15) inset;
}
.checkout-cart-index .data-table tfoot td {
  box-shadow: 0 4px 6px -4px rgba(0, 0, 0, 0.15) inset;
}
.checkout-cart-index .data-table tr.even, .data-table tr.odd {
  background-color: #FFFFFF;
}
.checkout-cart-index .data-table th {
  font-weight: 400;
  padding: 18px 23px;
}
.checkout-cart-index .data-table td {
  padding: 8px;
}
@media only screen and (min-width: 767px) {
    .checkout-cart-index .data-table td {
        padding: 15px;
    }
}
.checkout-cart-index .data-table td.td-edit, .data-table td.td-price {
  padding: 26px 18px;
}
.checkout-cart-index .data-table td.td-image {
  border-left: medium none;
}
.checkout-cart-index .data-table td.td-image img {
  height: auto;
  width: 115px;
}
.checkout-cart-index .data-table td.td-delete {
  border-right: medium none;
}
.checkout-cart-index .data-table .product-name {
  font-weight: 700;
}
.checkout-cart-index .data-table .td-title {
  display: none;
}
.checkout-cart-index .data-table .td-actions {
  display: none;
}
.checkout-cart-index .data-table .btn-continue {
  float: left;
}
.checkout-cart-index .data-table button.button span {
  font-size: 12px;
  text-transform: lowercase;
}

.checkout-cart-index .data-table thead, .data-table tfoot {
  background: none repeat scroll 0 0 #FAFAFA;
}

.checkout-cart-index .cart .first, .checkout-cart-index .cart .second, .checkout-cart-index .cart .third {
  border-radius: 0px;
  position: static;
  box-shadow: 0px 0px 0px #888888;
}

.checkout-cart-index .cart .cart-collaterals .col2-set {
  float: left;
  margin-top: 10px;
  padding: 10px;
  width: 370px;
  max-width: 100%;
}

.checkout-cart-index .cart .cart-collaterals .totals {
  float: right;
  margin-top: 10px;
  padding: 10px;
}
/* checkout-cart-index page end */

@media (min-width: 768px) {
    .checkout-cart-index .cart .checkout-cart-main {
        max-width: none;
    }
    .checkout-cart-index .cart .img-box {
        padding-right: 30px;
    }
    .checkout-cart-index .cart .crossells {
        float: right;
        padding-left: 30px;
        border-left: 1px solid #dedede;
    }
    .checkout-cart-index .cart span.header-anonce {
        float: left;
        text-align: left;
        margin-top: 8px;
    }
    .checkout-cart-index .cart .btn-proceed-checkout {
        float: right;
    }
    .checkout-cart-index .cart .img-box {
        display: block;
    }
    .checkout-cart-index .cart span.crossells-title {
      font-size: 21px;
    }
    .checkout-cart-index .cart span.crossells-title-line2 {
      font-size: 17px;
    }
    .checkout-cart-index .crossel-wrapper {
        padding: 5px;
    }
    .checkout-cart-index .cart .crossel-image-name,
    .checkout-cart-index .cart .crossel-image-price {
        font-size: 13px;
    }
    .checkout-cart-index .cart a.add-to-cart {
        font-size: 12px;
    }
}

.b-modal {
    overflow: hidden;
}

.b-modal .modal-dialog {
    width: 440px;
    top: 30%;
    background: #FFFFFF;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px rgba(0, 0, 0, 0.14), 0 1px 10px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    padding: 16px;
}

.b-modal .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
}

.b-modal .header .title {
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    color: #2C2C2C;
}

.b-modal .header .close{
    opacity: unset;
}

.b-modal .header .close:after{
    display: inline-block;
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.4099 8.99994L16.7099 2.70994C16.8982 2.52164 17.004 2.26624 17.004 1.99994C17.004 1.73364 16.8982 1.47825 16.7099 1.28994C16.5216 1.10164 16.2662 0.99585 15.9999 0.99585C15.7336 0.99585 15.4782 1.10164 15.2899 1.28994L8.99994 7.58994L2.70994 1.28994C2.52164 1.10164 2.26624 0.99585 1.99994 0.99585C1.73364 0.99585 1.47824 1.10164 1.28994 1.28994C1.10164 1.47825 0.995847 1.73364 0.995847 1.99994C0.995847 2.26624 1.10164 2.52164 1.28994 2.70994L7.58994 8.99994L1.28994 15.2899C1.19621 15.3829 1.12182 15.4935 1.07105 15.6154C1.02028 15.7372 0.994141 15.8679 0.994141 15.9999C0.994141 16.132 1.02028 16.2627 1.07105 16.3845C1.12182 16.5064 1.19621 16.617 1.28994 16.7099C1.3829 16.8037 1.4935 16.8781 1.61536 16.9288C1.73722 16.9796 1.86793 17.0057 1.99994 17.0057C2.13195 17.0057 2.26266 16.9796 2.38452 16.9288C2.50638 16.8781 2.61698 16.8037 2.70994 16.7099L8.99994 10.4099L15.2899 16.7099C15.3829 16.8037 15.4935 16.8781 15.6154 16.9288C15.7372 16.9796 15.8679 17.0057 15.9999 17.0057C16.132 17.0057 16.2627 16.9796 16.3845 16.9288C16.5064 16.8781 16.617 16.8037 16.7099 16.7099C16.8037 16.617 16.8781 16.5064 16.9288 16.3845C16.9796 16.2627 17.0057 16.132 17.0057 15.9999C17.0057 15.8679 16.9796 15.7372 16.9288 15.6154C16.8781 15.4935 16.8037 15.3829 16.7099 15.2899L10.4099 8.99994Z' fill='black'/%3E%3C/svg%3E%0A");
    width: 16px;
    height: 16px;
}

.b-modal .body {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    font-weight: 200;
    font-size: 16px;
    line-height: 22px;
}

.b-modal .btn {
    width: 100%;
    height: 44px;
    border-radius: 4px !important;
    font-weight: 200;
    font-size: 16px;
}

.b-modal .loader {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #FFFFFF;
    opacity: 0.75;
    z-index: 10001;
}

.b-modal .spinner {
    width: 50px;
    height: 50px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    background: conic-gradient(from 90deg at 50% 50%, #667080 0deg, rgba(102, 112, 128, 0) 0.04deg, #667080 227.29deg, #667080 360deg);
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

.b-modal .spinner::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background: #FFFFFF;
}

@keyframes rotation {
    0% { transform: rotate(0deg) }
    100% { transform: rotate(360deg)}
}

@media (max-width: 767px) {
    .b-modal .modal-dialog {
        position: fixed;
        bottom: 0;
        top: auto;
        margin: 0;
        width: 100%;
    }
}
